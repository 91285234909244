<template>
  <section>
    <div class="group-nav shadow"  >
      <div class="flex" v-if="category['name'+ lang_select] != null">
        <b class="flex-item flex-1 flex-center" @click="$router.push({ path: '/' })">
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </b>
        <div class="flex-item flex-11 group-nav-title pad-bot">{{ category['name'+ lang_select]}}</div>
        <div class="flex-item flex-1 flex-center language-slect">
          <select class="select-css flex-center" v-model="lang_select" @change="updateLang(lang_select)">
            <option value="">EN</option>
            <!-- <option value="_th">TH</option> -->
            <!-- <option value="_ja">JA</option>
            <option value="_cn">CN</option> -->
            <!-- <option value="_my">MY</option> -->
          </select>
        </div>
      </div>
      <div class="flex" v-if="category['name'+ lang_select] == null">
        <b class="flex-item flex-1 flex-center" @click="$router.push({ path: '/' })">
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </b>
        <div class="flex-item flex-11 group-nav-title pad-bot">{{ category['name']}}</div>
        <div class="flex-item flex-1 flex-center language-slect">
          <select class="select-css flex-center" v-model="lang_select" @change="updateLang(lang_select)">
            <option value="">EN</option>
            <!-- <option value="_th">TH</option> -->
            <!-- <option value="_ja">JA</option>
            <option value="_cn">CN</option> -->
            <!-- <option value="_my">MY</option> -->
          </select>
        </div>
      </div>
      <div class="flex-item group-nav-nav">
        <div
          class="group-nav-nav-item"
          v-for="group in category.items"
          :key="group.id"
          :class="isActive(group.id) ? 'is-active' : ''"
        >
          <router-link :to="'#' + group.id" tag="p">{{ group['name'+ lang_select] }} </router-link>
          <router-link :to="'#' + group.id" tag="p" v-if="group['name'+ lang_select] == null">{{ group['name'] }} </router-link>
        </div>
      </div>
    </div>
    <!-- //////////////////////////////////// -->
    <!-- //////////////////////////////////// -->
    <div id="group-wrapper">
      <div
        :id="group.id"
        v-observe-visibility="visibilityChanged"
        class="group"
        v-for="group in category.items"
        :key="group.id"
      >
        <div class="group-header">
          <h1>{{ group['name' + lang_select] }}</h1>
          <h1  v-if="group['name'+ lang_select] == null" >{{ group['name'] }}</h1>
        </div>
        <div class="menu-wrapper">
          <div class="menu" v-for="item in group.items" :key="item.item_id">
            <div class="menu-image" v-if="item.picture">
              <progressive-img
                class="menu-image-z"
                :src="item.picture"
                :blur="30"
                placeholder="https://t4.ftcdn.net/jpg/02/07/87/79/240_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg"
                lazy="loading"
              />
              <!-- <img v-lazy="item.picture" :src="item.picture" lazy="loading" alt=""> -->
            </div>
            <div class="menu-content">
              <h2>{{ item['name' + lang_select] }}</h2>
              <h2 v-if="item['name' + lang_select] == null" >{{ item['name' ] }}</h2>
              <p>{{ item['detail' + lang_select] }}</p>
              <p v-if="item['detail' + lang_select] == ''">{{ item['detail']}}</p>
              <p class="text-bold">{{ parseFloat(item.price).toFixed(2) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="group-button">
      <button class="button" @click="$router.push({ path: '/' })">Back to Main</button>
    </div>
    <the-footer />
  </section>
</template>
<script>
import TheFooter from '@/components/TheFooter'
import { mapGetters } from 'vuex'
export default {
  components: {
    TheFooter
  },
  data() {
    return {
      activigGroup: '',
      lang_select: '',
      languagess: ['TH', 'EN', 'JP', 'LAO', 'MY'],
      test: [
        {
          name: 'en',
          name_th: 'th',
          name_ja: 'ja',
          name_cn: 'cn',
          name_my: 'my'
        }
      ]
    }
  },
  methods: {
    visibilityChanged(isVisible, entry) {
      if (isVisible) {
        this.setCurrentGroup(entry.target.id)
      }
    },
    setCurrentGroup(id) {
      this.activigGroup = id
    },
    isActive(id) {
      return this.activigGroup === id
    },
    updateLang(lang) {
      this.$store.dispatch('updateLang', lang)
    }
  },
  computed: {
    ...mapGetters([
      'getCategoryByIdapi'
    ]),
    category() {
      const cateId = this.$route.params.categoryId
      return this.$store.getters.getCategoryByIdapi(cateId)[0]
    },
    categoryApi() {
      return this.$store.state.menuDataapi
    },
    language() {
      const naa = this.test.map(items => items)
      // console.log(naa)
      return naa
    }
    // detailLang() {
    //   const det = this.test.map(item => {
    //     item
    //   })
    // }
  },
  watch: {
  },
  mounted() {
    this.lang_select = this.$store.state.language
    // setInterval(function() {
    //   this.$store.dispatch('updateLang', this.lang_select)
    // }, 500)
  }
}
</script>

<style lang="scss" scoped>
img[lazy='loading'] {
  background: rgba(1, 1, 1, 0.3);
  width: 100%;
  height: 300px;
}

img[lazy='loaded'] {
  background: rgba(1, 1, 1, 0.5);
  width: 100%;
  height: 300px;
}

.pad-bot{
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.menu-wrapper {
  max-width: 100%;
  grid-template-rows: repeat(12 auto);
  padding: 0;
  margin: 0;
  padding-top: 2em;
}

.language-slect {
  padding-right: 0.5rem !important;
}

#group-wrapper {
  margin-top: var(--nav-height);
}

.menu {
  border-radius: 25px;
  background: #dddddd;
  padding: 20px;
  margin: 20px;
}

.menu .menu-image img {
  max-width: 100%;
}

/* Override */
.menu-wrapper {
  color: white;
  text-align: center;
}

.menu-content {
  font-weight: 200;
  line-height: 2.5em;
  margin: 3em 0em;
  color: black;
  // border-style: solid;
  // border-width: 5px;
}

.menu-content h2 {
  font-size: 2em;
  font-weight: 200;
}

.text-bold {
  font-weight: 500;
  font-size: 1.5em;
  letter-spacing: 0.2em;
}

.group {
  padding-top: 50px;
  margin-bottom: 120px;
  box-shadow: burlywood;
}

.group-header {
  padding: 0 1em;
  border-bottom: solid grey 2px;
  border-top: solid grey 2px;
}

.group-nav {
  position: fixed;
  background: white;
  min-height: var(--nav-height);
  top: 0;
  left: 0;
  z-index: 1000;
}

.group-nav {
  display: grid;
  grid-template-rows: repeat(12, 1fr);
  grid-template-rows: auto auto;
  width: 100%;
}

.group-nav-title {
  text-transform: uppercase;
  font-size: 1.8em;
  text-align: center;
}

.group-nav-nav {
  display: flex;
  overflow: scroll;
  background: white;
}

/* this will hide the scrollbar in webkit based browsers - safari, chrome, etc */
.group-nav-nav::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.group-nav-nav-item {
  background: white;
  word-wrap: none;
  min-width: 200px;
}

.group-nav-nav-item > p {
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -o-pre-wrap; /* Opera */
  white-space: pre-wrap; /* Chrome */
  word-wrap: break-word; /* IE */
  width: 100%;
  text-align: center;
}

.is-active {
  border-bottom: solid grey 2px;
}

#group-button {
  text-align: center;
  padding: 0.5em;
}

#group-button > .button {
  padding: 1em;
  width: 170px;
  background: none;
  cursor: pointer;
}
.menu-image-z {
  background-color: #ffffff;
  transition: background-color 1s linear;
  -moz-transition: background-color 1s linear;
  -o-transition: background-color 1s linear;
  -webkit-transition: background-color 1s linear;
}
.shadow{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.select-css{
  display: block;
  font-size: 15px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: .2em 1.5em .2em .8em;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: .5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
  linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right .7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: .65em auto, 100%;
}
/* Hide arrow icon in IE browsers */
.select-css::-ms-expand {
  display: none;
}
/* Hover style */
.select-css:hover {
  border-color: #888;
}
/* Focus style */
.select-css:focus {
  border-color: #aaa;
  /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
  box-shadow: 0 0 1px 1px rgba(59, 153, 252, .7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}

/* Set options to normal weight */
.select-css option {
  font-weight:normal;
}

/* Support for rtl text, explicit support for Arabic and Hebrew */
*[dir="rtl"] .select-css, :root:lang(ar) .select-css, :root:lang(iw) .select-css {
  background-position: left .7em top 50%, 0 0;
  padding: .6em .8em .5em 1.4em;
}

/* Disabled styles */
.select-css:disabled, .select-css[aria-disabled=true] {
  color: graytext;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
}

.select-css:disabled:hover, .select-css[aria-disabled=true] {
  border-color: #aaa;
}
</style>
